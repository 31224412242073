@import url('https://fonts.googleapis.com/css2?family=Lato:wght@700&family=Rubik&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto');

:root {
    --focus-background-color: #03F182;
    --focus-text-color: #12171B;
    --active-text-color: #eeeeee;
    --primary-color: #12171B;
    --interactive-background-color: rgb(255 255 255 / 20%);
    --primary-background: #12171B;
    --primary-background-color: #12171B;
    --app-logo: url('./client/client-assets/logo.png');
    --portraitBackgroundImage: none;
    --secondary-background-color: #000;
    --secondary-background-color-RGBA: rgba(0, 0, 0, 0.00);
    --background-blur-Color: #0000004D;
    --focus-border-color: #03F182;
    --badge-color: #12171B;
    --focus-complementary-color: #FF0000;
    --interactive-background-color-lighten: rgba(255, 255, 255, 0.1);
    --font-family: 'Rubik';
    --player-icon-size: 25px;
    --player-header-text-size: 32px;
    --player-header-logo-size: 40px;
    --player-progress-text-size: 16px;
    --poster-image-aspect-ratio: 2/3;
}

html {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
}

#root {
    overflow: hidden;
    height: 100%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.webstv-icon {
    fill: black;
    width: 16px;
    height: 16px;
}


.webstv-iconTV {
    fill: black;
    width: 24px;
    height: 24px;
}

.webstv-icon-new {
    fill: #000;
    width: 22px;
    height: 22px;
}

::-webkit-scrollbar {
    height: 4px;
    border-radius: 8px;
    width: 9px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
}

::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
    border-radius: 8px;
}